const requiredKeys = [
  'amount',
  'apple_pay_available',
  'apple_pay_config',
  'google_pay_available',
  'google_pay_config',
  'cards',
  'customer_id',
  'customer_phone',
  'currency_code',
  'language',
  'operation',
  'payment_methods',
  'payment_services',
  'response',
  'state',
  'type',
  'session_id',
];

const keysToValidate = {
  cards: [
    'customer_id',
    'brand',
    'name_on_card',
    'number',
    'expiry_month',
    'expiry_year',
    'token',
    'pg_code',
    'is_preferred',
    'is_expired',
    'cvv_required',
    'delete_url',
    'submit_url',
  ],
  google_pay_config: [
    'code',
    'environment',
    'gateway',
    'gateway_merchant_id',
    'merchant_id',
    'merchant_name',
    'total_price',
    'currency_code',
    'country_code',
    'fee',
    'fee_description',
    'payment_url',
  ],
  apple_pay_config: [
    'domain',
    'merchant_id',
    'code',
    'shop_name',
    'amount',
    'fee',
    'fee_description',
    'currency_code',
    'country_code',
    'validation_url',
    'payment_url',
  ],
  payment_methods: [
    'code',
    'name',
    'pg',
    'type',
    'amount',
    'currency_code',
    'fee',
    'fee_description',
    'icon',
    'flow',
    'can_save_card',
  ],
};

const sentryIgnoreErrors = [
  'InvalidAccessError: Page already has an active payment session.', // Browser throws this error if user force double click apple pay button before payment sheet is not completely displayed
  'Another PaymentRequest UI is already showing in a different tab or window', // Browser throws this error if payment sheet is already and user clicks google pay button in another tab
  'TypeError: Failed to fetch', // Chrome throws this error if a request is in progress and user navigates away
  'TypeError: NetworkError when attempting to fetch resource.', // Firefox throws this error if a request is in progress and user navigates away
  'TypeError: cancelled', // Safari throws this error if a request is in progress and user navigates away,
  'TypeError: Load failed', // Safari throws this error if a request fails,
];

const appleButtonTypes = [
  'add-money',
  'book',
  'buy',
  'check-out',
  'continue',
  'contribute',
  'donate',
  'order',
  'pay',
  'plain',
  'reload',
  'rent',
  'set-up',
  'subscribe',
  'support',
  'tip',
  'top-up',
];


const googleButtonTypes = [
  'book',
  'buy',
  'checkout',
  'donate',
  'order',
  'pay',
  'plain',
  'subscribe',
];

const googleButtonColors = ['black', 'white', 'default'];

const appleButtonColors = ['black', 'white', 'white-outline'];

const gooleButtonSizeMode = ['static', 'fill'];

const googleButtonLocale = [
  'en',
  'ar',
  'bg',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'id',
  'it',
  'ja',
  'ko',
  'ms',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'zh',
];

const globalLanguageCodes = [
  'ab',
  'aa',
  'af',
  'ak',
  'sq',
  'am',
  'ar',
  'an',
  'hy',
  'as',
  'av',
  'ae',
  'ay',
  'az',
  'bm',
  'ba',
  'eu',
  'be',
  'bn',
  'bh',
  'bi',
  'bs',
  'br',
  'bg',
  'my',
  'ca',
  'ch',
  'ce',
  'ny',
  'zh',
  'zh-Hans',
  'zh-Hant',
  'cv',
  'kw',
  'co',
  'cr',
  'hr',
  'cs',
  'da',
  'dv',
  'nl',
  'dz',
  'en',
  'eo',
  'et',
  'ee',
  'fo',
  'fj',
  'fi',
  'fr',
  'ff',
  'gl',
  'gd',
  'gv',
  'ka',
  'de',
  'el',
  'kl',
  'gn',
  'gu',
  'ht',
  'ha',
  'he',
  'hz',
  'hi',
  'ho',
  'hu',
  'is',
  'io',
  'ig',
  'id',
  'in',
  'ia',
  'ie',
  'iu',
  'ik',
  'ga',
  'it',
  'ja',
  'jv',
  'kl',
  'kn',
  'kr',
  'ks',
  'kk',
  'km',
  'ki',
  'rw',
  'rn',
  'ky',
  'kv',
  'kg',
  'ko',
  'ku',
  'kj',
  'lo',
  'la',
  'lv',
  'li',
  'ln',
  'lt',
  'lu',
  'lg',
  'lb',
  'gv',
  'mk',
  'mg',
  'ms',
  'ml',
  'mt',
  'mi',
  'mr',
  'mh',
  'mo',
  'mn',
  'na',
  'nv',
  'ng',
  'nd',
  'ne',
  'no',
  'nb',
  'nn',
  'ii',
  'oc',
  'oj',
  'cu',
  'or',
  'om',
  'os',
  'pi',
  'ps',
  'fa',
  'pl',
  'pt',
  'pa',
  'qu',
  'rm',
  'ro',
  'ru',
  'se',
  'sm',
  'sg',
  'sa',
  'sr',
  'sh',
  'st',
  'tn',
  'sn',
  'ii',
  'sd',
  'si',
  'ss',
  'sk',
  'sl',
  'so',
  'nr',
  'es',
  'su',
  'sw',
  'ss',
  'sv',
  'tl',
  'ty',
  'tg',
  'ta',
  'tt',
  'te',
  'th',
  'bo',
  'ti',
  'to',
  'ts',
  'tr',
  'tk',
  'tw',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'vo',
  'wa',
  'cy',
  'wo',
  'fy',
  'xh',
  'yi',
  'ji',
  'yo',
  'za',
  'zu',
];

exports.requiredKeys = requiredKeys;
exports.keysToValidate = keysToValidate;
exports.sentryIgnoreErrors = sentryIgnoreErrors;
exports.appleButtonTypes = appleButtonTypes;
exports.googleButtonTypes = googleButtonTypes;
exports.googleButtonColors = googleButtonColors;
exports.appleButtonColors = appleButtonColors;
exports.gooleButtonSizeMode = gooleButtonSizeMode;
exports.googleButtonLocale = googleButtonLocale;
exports.globalLanguageCodes = globalLanguageCodes;
